* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  color: white;
  user-select: none;
}

.fullscreen {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.fullscreen.clicked {
  display: none;
}

.fullscreen-start {
  background: #000;
  color: #fff;
  padding: 30px;
}